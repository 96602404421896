import React, { useState , useContext } from "react";
import { Container, Avatar, Typography, TextField, Button, Box, 
  FormControlLabel, Checkbox, Link, Grid, Tab, Tabs
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CreateAccount from './CreateAccount';

import { UserContext } from "../../../Service/UserContext";
import User  from "../../../Service/User";

const getUserProfile =() => {

}



const setUserProfilecontext =() => {
  
}

const NavigateToUserProfile =() => {
  
}

const UpdateFialedLoginAttempt =() => {

}

const DisplayCreateNewAccount =() => {

}

const DisplayForgottenUsernameOrPassword =() => {

}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberUsername, setRememberUsername] = useState(false);
  const [rememberPassword, setRememberPassword] = useState(false);
  const [value, setValue] = useState(0);
  const { login } = useContext(UserContext);

  const handleLogin = async () => {
    try {
      //  const response = await User.login(email, password);
         const mockUserData = {
          name: "John Doe",
          email: "john.doe@example.com",
          type: "admin"
        };
        login(mockUserData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = (event) => {
        
  };

  function rememberMe(field, value){
    if(rememberUsername){
      localStorage.setItem(field, value); 
    }
    else{
      localStorage.setItem(field, ""); localStorage.setItem(field, "")
    }
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}




  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Sign In" {...a11yProps(0)} />
          <Tab label="Create New Account" {...a11yProps(1)} />
           
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoFocus
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox checked={rememberUsername} onChange={(e) => setRememberUsername(e.target.checked)} color="primary" />}
            label="Remember Username"
          />
          <FormControlLabel
            control={<Checkbox checked={rememberPassword} onChange={(e) => setRememberPassword(e.target.checked)} color="primary" />}
            label="Remember Password"
          />
          <Button
            type="submit"
            onClick={()=>{handleLogin()}}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot username or passowrd?
              </Link>
            </Grid>
            
          </Grid>
        </Box>
      </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <CreateAccount />
      </CustomTabPanel>
      
    </Container>
  );
}

export default SignIn;
