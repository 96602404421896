import axios from 'axios'; 

const baseUrl = "https://kimanibenjamin.com"; 
const Bible =  {
    getChapterText: async (book, chapter) => {
        let value = await axios.get(`${baseUrl}/verse/chapter/${book}/${chapter}`); 
        return value; 
    },

    getChaptersAndVerses: async (book) => {
        let value = await axios.get(`${baseUrl}/verse/book/${book}`); 
        return value; 
    }, 

    getLookup: async (book, chapter) => {
        return await axios.get(`${baseUrl}/verse/lookup`);; 
    },

    updateVerse: async(verseInfo) => {
        console.log(verseInfo)
        return await axios.post(`${baseUrl}/verse/Edit`, {id: 1, text: 'En el principio crió Dios los cielos, y la tierra.ssssssssssssssssss'} ); 
        
    }

    
}

export default Bible; 

